interface CardData {
  id?: string;
  title: string;
  count: number | string;
  Icon: React.ReactNode;
}
interface DashboardCardsProps {
  data: CardData[];
}

export const DashboardCards: React.FC<DashboardCardsProps> = ({ data }) => (
  <section className="grid md:grid-cols-column-three grid-cols-column gap-5 mb-5">
    {data.map((card) => (
      <div key={card.title} className="bg-elr-white-400 py-5 px-6">
        <div>
          <div className="rounded-full pb-2 inline-block">{card.Icon}</div>
          <div className="mb-5" />
          <p className="text-lg text-elr-black-300 leading-5 mb-3">
            {card.title}
          </p>
          <p className="text-3xl text-elr-black">{card.count}</p>
        </div>
      </div>
    ))}
  </section>
);
