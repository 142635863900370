export const marketTransaction = {
  id: 'marketTxn1',
  market: {
    items: [
      {
        id: 'item1',
        name: 'White Rice',
        price: 20000,
        quantity: 1,
        _id: 'item1',
      },
      {
        id: 'item2',
        name: 'Fried Titus Fish',
        price: 20000,
        quantity: 1,
        _id: 'item2',
      },
      {
        id: 'item3',
        name: 'Plantain',
        price: 20000,
        quantity: 1,
        _id: 'item3',
      },
    ],
    destinationAddress: '194 Obafemi Awolowo road, Ikeja City Mall, Lagos',
    totalAmount: 60000,
    purchasedAtMarket: {
      id: 'market1',
      name: 'Local Market',
      _id: 'market1',
    },
  },
  _id: 'marketTxn1',
};

export const addedProducts = [
  {
    id: '1',
    productName: 'Named Item',
    price: 'NGN 25,000',
    quantity: 50,
    category: 'Grains',
    imageUrl: 'https://via.placeholder.com/100',
  },
  {
    id: '2',
    productName: 'Named Item',
    price: 'NGN 25,000',
    quantity: 50,
    category: 'Tuber',
    imageUrl: 'https://via.placeholder.com/100',
  },
  {
    id: '3',
    productName: 'Named Item',
    price: 'NGN 25,000',
    quantity: 50,
    category: 'Seasoning',
    imageUrl: 'https://via.placeholder.com/100',
  },
  {
    id: '4',
    productName: 'Named Item',
    price: 'NGN 25,000',
    quantity: 50,
    category: 'Grains',
    imageUrl: 'https://via.placeholder.com/100',
  },
  {
    id: '5',
    productName: 'Named Item',
    price: 'NGN 25,000',
    quantity: 50,
    category: 'Tuber',
    imageUrl: 'https://via.placeholder.com/100',
  },
  {
    id: '6',
    productName: 'Named Item',
    price: 'NGN 25,000',
    quantity: 50,
    category: 'Seasoning',
    imageUrl: 'https://via.placeholder.com/100',
  },
  {
    id: '7',
    productName: 'Named Item',
    price: 'NGN 25,000',
    quantity: 50,
    category: 'Grains',
    imageUrl: 'https://via.placeholder.com/100',
  },
  {
    id: '8',
    productName: 'Named Item',
    price: 'NGN 25,000',
    quantity: 50,
    category: 'Tuber',
    imageUrl: 'https://via.placeholder.com/100',
  },
  {
    id: '9',
    productName: 'Named Item',
    price: 'NGN 25,000',
    quantity: 50,
    category: 'Seasoning',
    imageUrl: 'https://via.placeholder.com/100',
  },
  {
    id: '10',
    productName: 'Named Item',
    price: 'NGN 25,000',
    quantity: 50,
    category: 'Tuber',
    imageUrl: 'https://via.placeholder.com/100',
  },
];
