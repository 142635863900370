import { ElrButton, ElrModal } from 'ui-components';
import ErrorIcon from '../../../../assets/images/error-icon.svg';
import CSVIcon from '../../../../assets/images/csv_with_bg.svg';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  loading: boolean;
}
export const SalesTurnOffModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onClick,
  loading,
}) => (
  <ElrModal isOpen={isOpen} onClose={onClose}>
    <div className="flex flex-col items-center text-center py-10 px-6">
      <div className="flex items-center justify-center mb-8">
        <img src={ErrorIcon} alt="error-icon" className="h-20 w-20" />
      </div>
      <p className="text-2xl text-elr-black pb-4 w-11/12">
        You are about to deactivate the sales of your products
      </p>
      <p className="text-base text-center text-elr-black-400 w-10/12">
        All added products will be removed from the sales platform and will no
        longer be sold
      </p>
      <p className="text-base text-center text-elr-black-400 pt-4">
        Do you still want to proceed?
      </p>
      <div className="flex gap-2.5 mt-10 w-full">
        <ElrButton
          text="Yes, Deactivate It"
          className="py-2 px-4 w-full bg-elr-gray text-elr-black h-10 rounded-md"
          spinnerColor="Black"
          onClick={onClick}
          loading={loading}
        />
        <ElrButton
          text="No, Go Back"
          className="bg-elr-black text-white w-full py-2 px-4 h-10 rounded-md"
          onClick={onClose}
        />
      </div>
    </div>
  </ElrModal>
);

interface UpdateProductProps {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  loading: boolean;
}

export const UpdateProductModal: React.FC<UpdateProductProps> = ({
  isOpen,
  onClose,
  onClick,
  loading,
}) => (
  <ElrModal isOpen={isOpen} onClose={onClose}>
    <div className="flex flex-col items-center text-center py-10 px-6">
      <div className="flex items-center justify-center mb-8">
        <img src={CSVIcon} alt="error-icon" className="h-20 w-20" />
      </div>
      <p className="text-2xl text-elr-black pb-4 w-10/12">Update Products</p>
      <p className="text-base text-center text-elr-black-400 w-11/12">
        Update your products by making edits to the appropriate columns in the
        product file.
      </p>
      <p className="text-base text-center text-elr-black-400 pt-4">
        Download the current CSV to make updates.
      </p>
      <div className="flex gap-2.5 mt-10 w-full">
        <ElrButton
          text="Cancel"
          className="py-2 px-4 w-full bg-elr-gray text-elr-black h-10 rounded-md"
          spinnerColor="Black"
          onClick={onClose}
        />
        <ElrButton
          text="Download CSV"
          className="bg-elr-black text-white w-full py-2 px-4 h-10 rounded-md"
          onClick={onClick}
          loading={loading}
        />
      </div>
    </div>
  </ElrModal>
);

interface ViewProductsProps {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  loading: boolean;
  products: Array<{
    id: string;
    productName: string;
    imageUrl: string;
    price: string;
    quantity: number;
    category: string;
  }>;
}

export const ViewProductsModal: React.FC<ViewProductsProps> = ({
  isOpen,
  onClose,
  onClick,
  loading,
  products,
}) => (
  <ElrModal
    isOpen={isOpen}
    onClose={onClose}
    width="md:w-[70%] w-[80%]"
    height="md:h-[80%] h-[70%]"
  >
    <div className="flex flex-col gap-5 py-10 md:px-24 px-6">
      <div className="flex justify-between items-center flex-wrap gap-4">
        <p className="text-xl text-elr-black">
          Added Products ({products.length})
        </p>
        <div className="flex gap-2.5 flex-wrap">
          <ElrButton
            text="Import CSV"
            className="bg-elr-black text-white w-full sm:w-auto py-2 px-4 h-10 rounded-md"
            onClick={onClick}
            loading={loading}
          />
          <ElrButton
            text="Update Products"
            className="py-2 px-4 w-full sm:w-auto bg-elr-gray text-elr-black h-10 rounded-md"
            spinnerColor="Black"
            onClick={onClose}
          />
        </div>
      </div>
      <div className="border border-elr-gray-200 py-4 overflow-x-auto w-full relative">
        <table className="table-auto md:w-full w-screen">
          <thead>
            <tr className="border-b-2 border-elr-gray text-elr-black">
              <th className="font-normal py-4 text-left md:pl-20 pl-6">
                Product Name
              </th>
              <th className="font-normal py-4 text-center">Price</th>
              <th className="font-normal py-4 text-center">Quantity</th>
              <th className="font-normal py-4 text-center">Category</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr
                key={product.id}
                className={`border-b ${
                  index < products.length - 1
                    ? 'border-elr-gray-100'
                    : 'border-white'
                }`}
              >
                <td className="py-4 text-left md:pl-12 pl-6 flex items-center">
                  <img
                    src={product.imageUrl}
                    alt={product.productName}
                    className="w-10 h-10 mr-4 md:flex hidden"
                  />
                  <p className="text-elr-black text-opacity-60">
                    {product.productName}
                  </p>
                </td>
                <td className="py-4 text-center text-elr-black text-opacity-60">
                  {product.price}
                </td>
                <td className="py-4 text-center text-elr-black text-opacity-60">
                  {product.quantity}
                </td>
                <td className="py-4 text-center text-elr-black text-opacity-60">
                  {product.category}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </ElrModal>
);
